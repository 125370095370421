



import { Component, Mixins } from 'vue-property-decorator'
import Collect from '@/components/organisms/study/Collect.vue'
import Contents4 from '@/views/student/study/Contents4.vue'

@Component({
  components: {
    Collect,
  },
})
export default class Collect8 extends Mixins(Contents4) {}
